import React, { useRef, useEffect, useState } from "react";
import SearchBar from "./components/SearchBar";
import _ from "lodash";
import string from "randomstring";
import random from "random";

export interface data {
  sentence: string;
  character: {
    name: string;
    slug: string;
    house: {
      name: string;
      slug: string;
    };
  };
}

export const App = () => {
  const domain = "gotphrases.xyz";
  const [query, setQuery] = useState("");
  const divRef = useRef<any>(null);
  const formRef = useRef<any>(null);
  const [data, setData] = useState<data | undefined>();

  useEffect(() => {
    async function parse() {
      await fetch(`https://api.gameofthronesquotes.xyz/v1/random`)
        .then((res) => res.json())
        .then(
          (result) => {
            setData(result);
          },
          (error) => {}
        );
    }
    if (_.isEmpty(data)) {
      parse();
    }
  }, [data]);

  return (
    <div className="w-screen h-screen overflow-none bg-gray-200">
      <div
        ref={divRef}
        className={`w-full h-full flex flex-col items-center justify-center z-50`}
      >
        <h1 className="-mt-48 text-lg font-extrabold mb-4 text-pink-400">
          {domain.replace(".xyz", "")}
        </h1>
        <div className="mb-24">
          {data ? (
            <div className="max-w-xl p-3 bg-white rounded-lg">
              <div className="mb-4 text-lg font-bold text-gray-900">
                &ldquo;{data.sentence}&rdquo;
              </div>
              <div className="flex justify-between">
                <div className="text-sm italic text-gray-500">
                  {data.character.name}
                </div>
                <div className="ml-8 text-sm italic text-gray-500">
                  {data.character.house.name}
                </div>
              </div>
            </div>
          ) : (
            <div className="max-w-md p-3 bg-white rounded-lg">
              <div className="mb-4 text-lg font-bold text-gray-900 bg-gray-900 rounded-lg opacity-30">
                &ldquo;{string.generate(random.int(32))}&rdquo;
              </div>
              <div className="flex justify-between">
                <div className="text-sm italic text-gray-500 bg-gray-500 rounded-lg opacity-30">
                  {string.generate(random.int(8, 16))}
                </div>
                <div className="ml-8 text-sm italic text-gray-500 bg-gray-500 rounded-lg opacity-30">
                  {string.generate(random.int(16, 32))}
                </div>
              </div>
            </div>
          )}
        </div>
        <form
          ref={formRef}
          className="z-10 w-full px-5"
          action={`https://${domain}/s`}
          method="GET"
        >
          <div className="relative flex flex-row w-full max-w-5xl mx-auto transition-all bg-white rounded-lg hover:shadow-2xl focus-within:shadow-2xl ring-1 ring-gray-500 focus-within:ring-gray-700">
            <SearchBar formRef={formRef} query={query} setQuery={setQuery} />
            <button
              className="flex flex-col items-center justify-center w-24 p-2 transition-all rounded-lg hover:bg-gray-100 active:outline-none focus:outline-none"
              type="submit"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-8 h-8"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
